import React from 'react'
import Navigate from './Navigate';
import Header from './components/Header';


const Master = () => {
  return (
    <div>
        {/* Header  */}
        <Navigate />

    </div>
  )
}

export default Master;
