import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Master from './Master';

function App() {
  return (
    <div className="App">
        <Master />
    </div>
  );
}

export default App;
