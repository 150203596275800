import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';


const Home = () => {
  const [input, setInput] = useState('');
  const [coverimg, setCoverimg] = useState('');
  const [downloadlink, setDownloadlink] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadsec, setDownloadsec] = useState(false);
  // fetch url 
  const fetchUrl = async () => {
    if (input.length > 10) {
      setLoading(true);
      setDownloadsec(false);
      try {
        let url = `https://instagram-downloader32.p.rapidapi.com/instagram-new?instaUrl=${input}`;
        let instaData = await axios.get(url, {
          'method': 'GET',
          headers: {
            "Content-Type": "application/json",
            "Application": "application/json",
            "X-RapidAPI-Host": "instagram-downloader32.p.rapidapi.com",
            "X-RapidAPI-Key": "ee653ce200msh78cbb82c802bd09p134859jsna017c0aa0b8a"
          }
        });
        setCoverimg(instaData.data[0].cover);
        setDownloadlink(instaData.data[0].download_link);
        if (instaData.status === 200) {
          setLoading(false);
          setDownloadsec(true);
        }
      }
      catch {

      }
    }
    else {
      alert('Please Paste Instagram Reel Link');
    }
  }
  return (
    <>
      <section className='url-section'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className='title text-center'> Instagram Reels Download </h1>
            </div>
            <div className="col-lg-8 mx-auto text-center">
              <div className='d-lg-flex'>
                <input value={input} onChange={(e) => setInput(e.target.value)} type="text" className='input w-100' placeholder='Paste Instagram Reel Link Here' />
                <button className='alok-btn btn-download' onClick={fetchUrl}>Download</button>
              </div>
            </div>
          </div>
          {/* loading  */}
          <div className={loading ? 'loading-div mt-3 d-block' : 'loading-div mt-3 d-none'}>
            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <span className='text-white d-block mb-3'>Loading....</span>
                <Stack spacing={1} className='d-grid justify-content-center'>
                  <Skeleton variant="rectangular" width={200} height={340} />
                  <Skeleton variant="rectangular" width={200} height={40} />
                </Stack>

              </div>
            </div>
          </div>
          {/* after api fetch  */}
          <div className={downloadsec ? 'after-api-fetch' : 'after-api-fetch d-none'}>
            <div className="row">
              <div className="col-lg-8 mx-auto text-center">
                <div className="data-container">
                  <div className="insta-cover">
                    <div className="cover-img">
                      <img src={coverimg} alt="img" />
                    </div>
                    <Link to={downloadlink} className='alok-btn w-200 d-inline-block mt-4'>Download Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home;
