import React from 'react'
import { Link, Outlet } from 'react-router-dom'

const Header = () => {
  return (
    <div>
        <section className='header'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
                        <span className="d-block logo">Logo</span>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8 col-8 text-end">
                        <Link to='/' className='alok-btn text-uppercase'>Contact us</Link>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Header
