import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Test from './components/Test';


const Navigate = () => {
  return (
    <div>
        <BrowserRouter>
           <Header />
           <Routes>
               <Route path='/' element={<Home />} />
               <Route path='/test' element={<Test />} />
           </Routes>
        </BrowserRouter>
    </div>
  )
}

export default Navigate
